import React, { useEffect, useState } from "react";
import "./DevelopmentTip.scss";
export default function DevelopmentTip(props) {
  const [content, setContent] = useState(null);

  useEffect(() => {
    setContent({ __html: props.content });
  }, [props.content]);
  return (
    <div className="devtip-wrap">
      <div className="head">
        <div className="icon"></div>
      </div>
      <div className="devtip-content" dangerouslySetInnerHTML={content}></div>
    </div>
  );
}
