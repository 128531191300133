import React, { Component } from 'react';
import LoginService from './utils/services/login';
import Progress from './assets/progress.json';
import Flow from './assets/flow.json';
import AssetService from './utils/asset-load';
import logoutFetch from './assets/helpers.js/logoutFetch';
export const Context = React.createContext();

export class Provider extends Component {
  state = {
    isSidebarOpen: false,
    notifications: [],
    curModIndex: '',
    linkIndexes: [],
    curModLink: '',
    disabledArray: [],
    completedArray: [],
    moduleOpen: 0,
    curSectionIndex: 0,
    completedBadges: [],
    isAuth: { auth: false, loading: true },
  };
  componentDidMount() {
    this.progressInit();
  }

  changeProgressState = (module, progress) => {};

  progressInit = () => {
    this.setModProgressNumber();
    let linkArray = [];
    let forwardProgress;
    let disabledArray = [];
    Progress.progress.linkIndexes.map((num, i) => {
      // if(Flow[i+1].links.length === num) {
      //   // unlock the next module

      // }else{
      if (i >= Progress.progress.curModIndex) {
        if (Flow[i + 2]) {
          disabledArray.push(Flow[i + 2].url);
        }
      }
      // }
      linkArray.push(Flow[i + 1].links[num]);
    });

    this.setState({
      curModIndex: Progress.progress.curModIndex,
      lastVisitedModIndex: Progress.progress.curModIndex,
      linkIndexes: Progress.progress.linkIndexes,
      linkArray: linkArray,
      disabledArray: disabledArray,
      loading: false,
      moduleOpen: 0,
      curSectionIndex:
        Progress.progress.linkIndexes[Progress.progress.curModIndex],
    });
  };

  setModProgressNumber = () => {
    let hash = {};
    let allLinks = [];
    Flow.map((item, i) => {
      let split = item.url.split('/');
      hash[split[2]] = { length: item.links.length };
      let array = [];
      item.links.map((linkObject) => {
        let urlArray = linkObject.url.split('/');
        let timeline = AssetService.getTimeline(urlArray[4], urlArray[2]);
        array.push(timeline.length);
        timeline.map((data) => {
          allLinks.push(data.url);
        });
      });
      hash[split[2]].subLengths = array;
    });

    this.setState(
      {
        allLinks: allLinks,
        modSubLengths: hash,
      },
      () => {}
    );
    // Flow
    // sub flow
    // modulemodSubLengt
  };

  setTimelineProgress = (timelineNumber, location) => {
    let completedArray = [...this.state.completedArray];
    completedArray.push(location);
    this.setState({
      completedArray: completedArray,
    });
  };

  setCurModLink = (curModLink) => {
    this.setState({
      curModLink: curModLink,
    });
  };

  setCurModIndex = (curModIndex) => {
    this.setState({
      curModIndex: curModIndex,
    });
  };

  setLastVisitedModIndex = (lastVisitedModIndex) => {
    this.setState({
      lastVisitedModIndex: lastVisitedModIndex,
    });
  };

  setCurSectionIndex = (curSectionIndex) => {
    this.setState({
      curSectionIndex: curSectionIndex,
    });
  };

  updateDisabledArray = (removedItem) => {
    this.setState({
      disabledArray: this.state.disabledArray.filter(
        (arrayUrl) => arrayUrl !== removedItem
      ),
    });
  };

  setSidebarModuleOpen = (moduleOpen) => {
    this.setState({
      moduleOpen: moduleOpen,
    });
  };

  setUpdateLinkIndexes = (curModIndex) => {
    let newlinkIndexes = this.state.linkIndexes;
    newlinkIndexes[curModIndex] = newlinkIndexes[curModIndex] + 1;
    this.setState({
      linkIndexes: newlinkIndexes,
    });
  };

  toggleMenu = () => {
    this.setState(
      {
        isSidebarOpen: !this.state.isSidebarOpen,
      },
      () => {}
    );
  };
  closeMenu = () => {
    this.setState({
      isSidebarOpen: false,
    });
  };

  loginFetch = (userId) => {
    LoginService.login(userId);
    this.setState({
      userId: userId,
    });
  };
  setModalContent = (content) => {
    this.setState({
      modalContent: content,
    });
  };
  getProgressData = async () => {
    const url = process.env.REACT_APP_API_URL + '/api/progress';
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const json = await response.json();
        const stringResult = JSON.stringify(json);
        localStorage.setItem('progress', stringResult);
        this.progressInit();
        return json;
      } else {
        if (response.status === 401) {
          this.logout();
        }
      }
    } catch (error) {
      console.log('error', error);
      return false;
    }
  };

  postProgressData = async (mod, index, changeLink) => {
    if (
      mod > 8 ||
      (mod === 7 && index > 1) ||
      mod === undefined ||
      index === undefined
    ) {
      return;
    }
    const url = process.env.REACT_APP_API_URL + '/api/progress/hek';
    let payload = {
      modindex: mod,
      linkindex: index.toString(),
    };
    if (changeLink) {
      this.setCurModLink(Flow[mod].links[index].url);
    }
    this.setCurModIndex(mod);
    this.setCurSectionIndex(index);
    this.setSidebarModuleOpen(mod, mod - 1);
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const stringResult = JSON.stringify(payload);
        localStorage.setItem('progress', stringResult);
      } else {
        if (response.status === 401) {
          this.logout();
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  login = (token) => {
    if (token) {
      localStorage.setItem('token', token);
    }

    this.setState({
      isAuth: { auth: true, loading: false },
    });
  };

  logout = () => {
    localStorage.clear();
    this.setState({
      isSidebarOpen: false,
      notifications: [],
      curModIndex: '',
      linkIndexes: [],
      curModLink: '',
      disabledArray: [],
      completedArray: [],
      moduleOpen: 0,
      curSectionIndex: 0,
      completedBadges: [],
      isAuth: { auth: false, loading: false },
    });
    logoutFetch();
  };

  unlockModule = (link) => {};

  addCompletedBadge = (badge) => {
    this.setState({
      completedBadges: [...this.state.completedBadges, badge],
    });
  };

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          closeMenu: this.closeMenu,
          toggleMenu: this.toggleMenu,
          loginFetch: this.loginFetch,
          setTimelineProgress: this.setTimelineProgress,
          unlockModule: this.unlockModule,
          setCurrentModule: this.setCurrentModule,
          setCurModLink: this.setCurModLink,
          setLastVisitedModIndex: this.setLastVisitedModIndex,
          setCurModIndex: this.setCurModIndex,
          setCurSectionIndex: this.setCurSectionIndex,
          updateDisabledArray: this.updateDisabledArray,
          setSidebarModuleOpen: this.setSidebarModuleOpen,
          setUpdateLinkIndexes: this.setUpdateLinkIndexes,
          addCompletedBadge: this.addCompletedBadge,
          login: this.login,
          logout: this.logout,
          getProgressData: this.getProgressData,
          postProgressData: this.postProgressData,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const Consumer = Context.Consumer;
