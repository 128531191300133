import React, { useState, useEffect } from 'react';
import Modal from '../../shared/Modal/Modal';
import './ClickReveal.scss';
import DOMPurify from 'dompurify';

function ClickReveal(props) {
  let Content = import(`../../../assets/course/ClickReveal${props.path}`);
  const [content, changeContent] = useState(null);
  const [modalContent, changeModalContent] = useState(null);
  const [showModal, toggleModal] = useState(false);
  const [clicked, changeClicked] = useState([]);

  useEffect(() => {
    Content.then((data) => {
      changeContent(data);
    });
  }, [props.path]);

  const handleClickReveal = (data, index) => {
    const mySafeHTML = DOMPurify.sanitize(data.illness, {
      ADD_ATTR: ['target'],
    });
    let item = {
      value: data.value,
      illness: { __html: mySafeHTML },
    };

    changeClicked([...clicked, index]);
    changeModalContent(item);
    toggleModal(true);
  };

  return (
    <>
      {content ? (
        <>
          <div className='click-content'>
            {content.contents.map((item, i) => (
              <div
                className={`clickable-reveal ${
                  clicked.includes(i) ? 'clicked' : ''
                }`}
                onClick={() => handleClickReveal(item, i)}
              >
                {item.value}
              </div>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
      {showModal ? (
        modalContent ? (
          <Modal showModal={toggleModal} show={showModal}>
            <h2>{modalContent.value}</h2>
            <div dangerouslySetInnerHTML={modalContent.illness}></div>
          </Modal>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default ClickReveal;
