import React, { useState, useEffect, useContext } from 'react';
import resetPasswordCall from '../../../assets/helpers.js/resetPassword';
const ResetPassword = () => {
  const [form, setForm] = useState({});
  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    data: '',
    error: '',
    loading: false,
  });
  const handleChangeInput = (element) => {
    const { name, value } = element;
    setForm((prevState) => ({ ...prevState, [name]: value }));
    setResetPasswordInfo((prev) => ({ ...prev, error: '' }));
  };

  const getAttributes = (name, state, handleChange) => {
    return {
      name: name,
      value: state[name] || '',
      onChange: (e) => handleChange(e.target),
    };
  };

  return (
    <div className='auth-wrapper reset-p'>
      <div className='sign-in-b-wrapper'></div>
      <section>
        <div className='login-wrap'>
          <div className='inner-wrap'>
            <h1>Reset Password</h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (form.newPassword !== form.confirmNewPassword) {
                  setResetPasswordInfo((prev) => ({
                    ...prev,
                    error: 'Passwords do not match ',
                  }));
                } else {
                  setResetPasswordInfo({ ...resetPasswordInfo, loading: true });
                  resetPasswordCall(form.password, form.newPassword).then(
                    (item) => {
                      if (item) {
                        setResetPasswordInfo({
                          data: true,
                          error: false,
                          loading: false,
                        });
                        setForm({});
                      } else {
                        setResetPasswordInfo({
                          data: false,
                          error: 'Invalid Old Password',
                          loading: false,
                        });
                      }
                    }
                  );
                }
              }}
            >
              <label>
                <p>Password</p>
                <input
                  required
                  type='password'
                  {...getAttributes('password', form, handleChangeInput)}
                />
              </label>
              <label>
                <p>New Password</p>
                <input
                  required
                  type='password'
                  {...getAttributes('newPassword', form, handleChangeInput)}
                />
              </label>
              <label>
                <p>Confirm New Password</p>
                <input
                  required
                  type='password'
                  {...getAttributes(
                    'confirmNewPassword',
                    form,
                    handleChangeInput
                  )}
                />
              </label>
              {resetPasswordInfo.error && (
                <p style={{ color: 'red', textAlign: 'left' }}>
                  {resetPasswordInfo.error}
                </p>
              )}
              {resetPasswordInfo.data && (
                <p
                  style={{
                    color: 'green',
                    textAlign: 'left',
                    fontSize: '1rem',
                  }}
                >
                  Your password has been reset!
                </p>
              )}
              {/* {res.error && <p className='error'>{res.error}</p>} */}
              <div className='button-wrap'>
                <button className='button' type='submit'>
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
