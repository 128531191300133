let allHandouts = {};

allHandouts["vax1"] = require("../../../assets/handouts/vax1.pdf").default;
allHandouts["vax2"] = require("../../../assets/handouts/vax2.pdf").default;
allHandouts["vax3"] = require("../../../assets/handouts/vax3.pdf").default;
allHandouts["vax4"] = require("../../../assets/handouts/vax4.pdf").default;
allHandouts["vax5"] = require("../../../assets/handouts/vax5.pdf").default;
allHandouts["vax6"] = require("../../../assets/handouts/vax6.pdf").default;
allHandouts["vax7"] = require("../../../assets/handouts/vax7.pdf").default;
allHandouts["vax8"] = require("../../../assets/handouts/vax8.pdf").default;
allHandouts["vax9"] = require("../../../assets/handouts/vax9.pdf").default;
allHandouts["oH1"] = require("../../../assets/handouts/oH1.pdf").default;
allHandouts["safe1"] = require("../../../assets/handouts/safe1.pdf").default;
// allHandouts["handout53"] =
//   require("../../../assets/handouts/ho53.pdf").default;
// allHandouts["handout61"] =
//   require("../../../assets/handouts/ho61.pdf").default;
// allHandouts["handout62"] =
//   require("../../../assets/handouts/ho62.pdf").default;
// allHandouts["handout63"] =
//   require("../../../assets/handouts/ho63.pdf").default;

export default allHandouts;
