let allContentImages = {};

allContentImages["image11"] =
  require("../../../assets/images/respiratory system.png").default;
allContentImages["image11a"] =
  require("../../../assets/images/image11a.png").default;
allContentImages["image12"] =
  require("../../../assets/images/image12.png").default;
allContentImages["image13"] =
  require("../../../assets/images/image13.png").default;
allContentImages["image14"] =
  require("../../../assets/images/image14.png").default;
allContentImages["image21a"] =
  require("../../../assets/images/image21a.png").default;
allContentImages["image22"] =
  require("../../../assets/images/image22.png").default;
allContentImages["image23"] =
  require("../../../assets/images/image23.png").default;
allContentImages["image24"] =
  require("../../../assets/images/image24.png").default;
allContentImages["image31"] =
  require("../../../assets/images/image31.png").default;
allContentImages["image32"] =
  require("../../../assets/images/image32.png").default;
allContentImages["image33"] =
  require("../../../assets/images/image33.png").default;
allContentImages["image41"] =
  require("../../../assets/images/image41.png").default;
allContentImages["image42"] =
  require("../../../assets/images/image42.png").default;
allContentImages["image43"] =
  require("../../../assets/images/image43.jpg").default;
allContentImages["image44"] =
  require("../../../assets/images/image44.png").default;
allContentImages["image45"] =
  require("../../../assets/images/image45.png").default;
allContentImages["image51"] =
  require("../../../assets/images/image51.png").default;
allContentImages["image52"] =
  require("../../../assets/images/image52.png").default;
allContentImages["image53"] =
  require("../../../assets/images/image53.png").default;
allContentImages["image54"] =
  require("../../../assets/images/image54.png").default;
allContentImages["image61"] =
  require("../../../assets/images/image61.png").default;
allContentImages["image62"] =
  require("../../../assets/images/image62.png").default;
allContentImages["image63"] =
  require("../../../assets/images/image63.png").default;
allContentImages["image64"] =
  require("../../../assets/images/image64.png").default;
allContentImages["image65"] =
  require("../../../assets/images/image65.png").default;
allContentImages["image66"] =
  require("../../../assets/images/image66.png").default;
allContentImages["image67"] =
  require("../../../assets/images/image67.png").default;
allContentImages["image71"] =
  require("../../../assets/images/image71.png").default;
allContentImages["image72"] =
  require("../../../assets/images/image72.png").default;

export default allContentImages;
