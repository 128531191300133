import React from 'react';
import './video-player.scss';
import Vimeo from '@u-wave/react-vimeo';
// import ReactPlayer from "react-player"

function VimeoPlayer(props) {
  return (
    <>
      <Vimeo
        video={props.source}
        controls={true}
        showTitle={true}
        showPortrait={false}
        onPlay={props.onStart}

        // responsive={true}
      />
    </>
  );
}

export default VimeoPlayer;
