import React, { useEffect, useContext, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Context } from '../../context';
import Timeline from '../core/timeline/timeline';
import './Module.scss';
import { gsap } from 'gsap';

function Module(props) {
  const context = useContext(Context);
  const [current, changeCurrent] = useState('');
  const [redirectLink, setRedirectLink] = useState('');

  useEffect(() => {
    changeCurrent('');
    context.state.linkIndexes ? (
      changeCurrent(props.data.links[context.state.linkIndexes[props.modIndex]])
    ) : (
      <></>
    );
  }, [props.parentRoute, props.modIndex, context.state]);

  const getRedirectLink = (parentLink, modIndex) => {
    return (
      <Redirect
        from={parentLink}
        to={props.data.links[context.state.linkIndexes[modIndex]]}
      />
    );
  };

  return (
    <>
      <div className='module-content-wrap'>
        <Switch>
          {props.data.links.map((link) => {
            return (
              <Route key={1} path={link.url}>
                <Timeline
                  nextData={props.nextData}
                  lastData={props.lastData}
                  allLinks={props.data.links}
                  parentRoute={link.url}
                  path={link}
                />
              </Route>
            );
          })}
          {/* {
            getRedirectLink(props.parentRoute, props.modIndex)
          } */}
          {/* {
          redirectLink ? 
          <Redirect from={props.parentRoute} to={getRedirectLink()} />:
          <></>

        } */}
        </Switch>
        <></>
      </div>
    </>
  );
}

export default Module;
