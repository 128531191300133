const setFirstVideo = async () => {
  const bearerToken = `Bearer ${localStorage.getItem('token')}`;

  try {
    let data = await fetch(
      process.env.REACT_APP_API_URL + `/api/progress/initialVideo`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: bearerToken,
        },
      }
    );
    if (data.ok) {
    } else {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export default setFirstVideo;
