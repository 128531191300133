const badges = [
  {
    badgeName: 'badge11',
    urlUsed: '/course/module1/timeline/two-truths1/intro',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge12',
    urlUsed: '/course/module1/timeline/test-mod1/test',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge13',
    urlUsed: 'course/module1/timeline/more-mod1/common',
    type: 'module',
    slide: 1,
  },
  {
    badgeName: 'badge21',
    urlUsed: '/course/module2/timeline/two-truths2/intro',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge22',
    urlUsed: '/course/module2/timeline/test-mod2/test',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge23',
    urlUsed: '/course/module2/timeline/more-mod2/immunize',
    type: 'module',
    slide: 1,
  },
  {
    badgeName: 'badge31',
    urlUsed: '/course/module3/timeline/two-truths3/intro',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge32',
    urlUsed: '/course/module3/timeline/test-mod3/test',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge33',
    urlUsed: '/course/module3/timeline/more-mod3/safety',
    type: 'module',
    slide: 1,
  },
  {
    badgeName: 'badge41',
    urlUsed: '/course/module4/timeline/two-truths4/intro',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge42',
    urlUsed: '/course/module4/timeline/test-mod4/test',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge43',
    urlUsed: '/course/module4/timeline/more-mod4/nutrition',
    type: 'module',
    slide: 1,
  },
  {
    badgeName: 'badge51',
    urlUsed: '/course/module5/timeline/two-truths5/intro',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge52',
    urlUsed: '/course/module5/timeline/test-mod5/test',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge53',
    urlUsed: '/course/module5/timeline/more-mod5/oral-health',
    type: 'module',
    slide: 1,
  },
  {
    badgeName: 'badge61',
    urlUsed: '/course/module6/timeline/two-truths6/intro',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge62',
    urlUsed: '/course/module6/timeline/test-mod6/test',
    type: 'activity',
    slide: 0,
  },
  {
    badgeName: 'badge63',
    urlUsed: '/course/module6/timeline/more-mod6/outdoor-safety',
    type: 'module',
    slide: 1,
  },
];

const makeBadge = (badgeList) => {
  return badgeList.map((badge) => ({ ...badge, checked: false }));
};

const allBadges = makeBadge(badges);

export default allBadges;
