const getPDF = async () => {
  const bearerToken = `Bearer ${localStorage.getItem('token')}`;
  try {
    let data = await fetch(process.env.REACT_APP_API_URL + `/api/certbuilder`, {
      method: 'POST',
      mode: 'cors',

      headers: {
        'Content-Type': 'application/blob',
        Authorization: bearerToken,
      },
    });
    const response = await data.blob();

    if (!data.ok) {
      throw new Error();
    } else {
      return URL.createObjectURL(response);
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export default getPDF;
