import React, { useState, useContext } from 'react';
import './FaceSurvey.scss';
import { Context } from '../../../context.js';
import { gsap } from 'gsap';
import submitSurvey from '../../../assets/helpers.js/submitSurvey';

export default function FaceSurvey() {
  const [selection, changeSelection] = useState(null);
  const context = useContext(Context);
  const options = [
    [
      { name: 'Very Helpful', id: 1 },
      { name: 'Helpful', id: 2 },
    ],
    [
      { name: 'A Little Helpful', id: 3 },
      { name: 'Not Helpful', id: 4 },
    ],
  ];
  const selectedClass = {
    width: '12em',
    height: '14.8em',
  };

  const unselectedClass = {
    width: '10em',
    height: '12em',
  };

  const handleClick = (i) => {
    submitSurvey(context.state.curModIndex - 1, i);
    changeSelection(i);

    gsap.to(`.face${i + 1}`, 0.1, selectedClass);
    options.map((item, index) => {
      if (i !== index) {
        gsap.to(`.face${index + 1}`, 0.1, unselectedClass);
      }
    });
  };

  return (
    <>
      {selection || selection === 0 ? (
        <div>
          <section className='ready-to-complete'>
            <p className='submission-response'>
              Thank you! We appreciate your feedback.
            </p>
          </section>
        </div>
      ) : (
        <div className='face-buttons'>
          <div>
            {options[0].map((item, i) => (
              <div
                key={i}
                onClick={() => handleClick(item.id)}
                className={`face${i + 1} face ${
                  selection === i ? 'selected' : ''
                }`}
                data={item}
              >
                <div className={`face-name`}>{item.name}</div>
              </div>
            ))}
          </div>
          <div>
            {options[1].map((item, i) => (
              <div
                key={i}
                onClick={() => handleClick(item.id)}
                className={`face${i + 3} face ${
                  selection === i ? 'selected' : ''
                }`}
                data={item}
              >
                <div className={`face-name`}>{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
