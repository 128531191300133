const resetPasswordCall = async (oldpass, newpass) => {
  const bearerToken = `Bearer ${localStorage.getItem('token')}`;

  try {
    let data = await fetch(process.env.REACT_APP_API_URL + `/api/pwdreset`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerToken,
      },
      body: JSON.stringify({ oldpass, newpass }),
    });
    if (data.ok) {
      return true;
    } else {
      throw new Error();
    }
  } catch (err) {
    return false;
  }
};

export default resetPasswordCall;
