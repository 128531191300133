import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ProfilePage from "../../components/core/profile/ProfilePage.js";
import { Consumer, Context } from "../../context.js";
import SideBar from "../../components/core/sidebar/sidebar.jsx";
import Header from "../../components/core/header/header.jsx";
import Flow from "../../assets/flow.json";
import "./page-content.scss";
import Module from "../../components/course/Module.jsx";
import Overview from "../../components/course/Overview/overview.jsx";
import { gsap } from "gsap";
import RightProgress from "../../components/core/right-progress/RightProgress.jsx";
import Login from "../../components/core/login/login.jsx";
import SignUp from "../../components/core/login/SignUp.js";
import Routes from "./Routes.js";

function PageContent(props) {
  const context = useContext(Context);
  return <Consumer>{(item, i) => <Routes key={i} />}</Consumer>;
}

export default PageContent;
