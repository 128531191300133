import React, { useEffect, useState } from 'react';
// import "../handouts/handouts.scss";
import allContentImages from './allContentImages';

function Image(props) {
  let image = props.content;
  const imageToRender = allContentImages[image];

  const [content, setContent] = useState(null);
  useEffect(() => {
    setContent({ __html: props.data });
  }, [props.data]);

  return (
    <>
      <div className='contentImage'>
        {/* {content ? <div dangerouslySetInnerHTML={content}></div> : <></>} */}
        {/* {content ? (
          <div>
            <h2>Click to review handout:</h2>
            <b> </b>
            <a href={props.content} target="_blank" rel="noreferrer">
              {props.content}
            </a>
          </div>
        ) : (
          <></>
        )} */}
        {content ? (
          <div>
            <img src={imageToRender} alt='this one' />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Image;
