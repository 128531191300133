import React, { useEffect, useContext } from 'react';
// import "../handouts/handouts.scss";
import './badges.scss';
import { Context } from '../../../context';
function ActivityBadge({ showBadge, dispatch, ...props }) {
  const context = useContext(Context);

  useEffect(() => {
    if (showBadge === true) {
      context.addCompletedBadge(props.content);
      setTimeout(() => {
        dispatch({ type: 'hide' });
      }, 4000);
    }
  }, [showBadge]);

  return (
    <>
      {props.content && (
        <div className='badge-container'>
          <div className='badge-wrapper'>
            <div className={`badge ${showBadge ? 'open' : 'closed'}`}>
              <h2 style={{ fontSize: '1.4rem' }}>You Earned a Badge!</h2>
              <img
                src={
                  require(`../../../assets/badges/${props.content}.png`).default
                }
                alt='this badge'
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ActivityBadge;
