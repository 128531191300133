import React, { useEffect, useState, useReducer } from 'react';
import './PlanIt.scss';

const reducer = (state, action) => {
  if (state.checkedIds.includes(action.id)) {
    return {
      ...state,
      checkedIds: state.checkedIds.filter((id) => id !== action.id),
    };
  }

  if (state.checkedIds.length >= 4) {
    return state;
  }

  return {
    ...state,
    checkedIds: [...state.checkedIds, action.id],
  };
};

export default function PlanIt(props) {
  let Content = import(`../../../assets/course/PlanIt${props.path}`);
  const [content, changeContent] = useState(null);
  const initialState = { checkedIds: [] };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    Content.then((data) => {
      changeContent(data);
    });
  }, [props.path]);

  return (
    <div className='plan-it-wrap'>
      {content ? (
        <div className={`${content.wrapName}`}>
          <div dangerouslySetInnerHTML={{ __html: content.static }}></div>
          <div className='select-section'>
            {content.select.map((item, id) => (
              <div className='select-item-wrap'>
                <input
                  id={id}
                  type='checkbox'
                  onChange={() => dispatch({ id })}
                  checked={state.checkedIds.includes(id)}
                />
                <label htmlFor='' key={item + 1}>
                  {item}{' '}
                </label>
              </div>
            ))}
            {content.fillIn ? (
              <>
                <div className='select-item-wrap'>
                  <input type='checkbox' />
                  <label htmlFor=''>{content.fillIn.instruct}</label> <br />
                </div>
                <textarea className='other-section'></textarea>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
