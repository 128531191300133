import React, { useState, useEffect, Fragment } from "react";
import "./reflection-questions.scss";
import { ReactComponent as LightBulb } from "../../../assets/images/lightbulb[17].svg";
import Response from "../quiz/response";
import DOMPurify from "dompurify";
import Dynamic from "./Dynamic";
import Modal from "../Modal/Modal";

function ReflectionQuestions(props) {
  return (
    <>
      <div className="reflect-wrap">
        <div className="reflect-header-wrap">
          <div className="icon">
            <LightBulb />
          </div>
          <h2>Reflection Questions</h2>
        </div>
        {props.questionsIntro ? (
          <div
            className=" rq-intro"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.questionsIntro),
            }}
          />
        ) : (
          <></>
        )}

        {/* Static Questions */}
        <ol>
          {props.questions.map((question, i) => (
            <>
              <li
                key={i}
                className="rQuestion"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(question),
                }}
              ></li>
              <br />

              {props.dynamic ? (
                props.dynamic.map((item, index) => (
                  <Fragment>
                    {item.order === i ? (
                      <Dynamic data={item} questionNumber={index + 1} />
                    ) : (
                      <></>
                    )}
                  </Fragment>
                ))
              ) : (
                <></>
              )}
            </>
          ))}
        </ol>
      </div>
    </>
  );
}

export default ReflectionQuestions;
