import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../context';
import './login.scss';
import { Redirect } from 'react-router-dom';
import { useForm } from '../../../customHooks/useForm';
import useFetch from '../../../customHooks/useFetch';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Flow from '../../../assets/flow.json';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function Login() {
  const [formState, handleChange, setState] = useForm();
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  const [res, callAPI] = useFetch({
    url: 'login',
    method: 'POST',
    payload: formState,
    responseFormat: 'JSON',
    headers: {},
  });

  const context = useContext(Context);
  useEffect(() => {
    if (res.error) {
      setState({});
      setError("The username or password you've entered is incorrect");
    }
    if (res.data) {
      if (res.data.program === 'HEK') {
        localStorage.setItem('name', res.data.name);
        localStorage.setItem('initialVideo', res.data.initialVideo);
        context.login(res.data.token);
        context.getProgressData().then((result) => {
          console.log('login');
          if (result) {
            const token = localStorage.getItem('token');
            context.login(token);
            history.push('/course/overview');
          }
        });
      } else {
        setError('Oops looks like that is a ezParent Log in...');
      }
    }
  }, [res, setState]);
  const submitLogin = (e) => {
    e.preventDefault();
    callAPI();
  };

  return (
    <div className='auth-wrapper'>
      <div className='sign-in-b-wrapper'>
        <div>
          <p>Need to Create an Account?</p>
          <p>
            <Link to={'/reset-password'}>I forgot my Username or Password</Link>
          </p>
        </div>
        <Link className='sign-up' to='/sign-up'>
          Create new account
        </Link>
      </div>
      <section>
        <div className='logo'>&nbsp;</div>
        <div className='login-wrap'>
          <div className='inner-wrap'>
            <h1>Sign In</h1>
            <form onSubmit={(e) => submitLogin(e)}>
              <label>
                <p>Username</p>
                <input
                  required
                  name='username'
                  type='text'
                  value={formState['username'] || ''}
                  onChange={(e) => {
                    handleChange(e);
                    setError('');
                  }}
                />
              </label>
              <label>
                <p>Password</p>
                <input
                  required
                  value={formState['password'] || ''}
                  onChange={(e) => {
                    handleChange(e);
                    setError('');
                  }}
                  name='password'
                  type='password'
                />
              </label>
              {error && <p className='error'>{error}</p>}
              <div className='button-wrap'>
                {!res.loading && (
                  <button className='button' type='submit'>
                    Login
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
