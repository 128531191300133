import React, { useEffect, useState } from 'react';
import '../handouts/handouts.scss';
import allHandouts from './allHandouts';

function Handout(props) {
  let handOut = props.content.hONum;
  let handOuts = props.content;
  let handOuttoRender = allHandouts[handOut];

  const [content, setContent] = useState(null);
  useEffect(() => {
    setContent({ props });
  }, [props.moduleHandouts]);

  return (
    <>
      <div className='handout'>
        <div>
          Handouts
          <ul>
            {handOuts.map((hO, h) => {
              return (
                <>
                  <li>
                    <a
                      href={allHandouts[hO.hONum]}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {hO.title}
                    </a>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Handout;
