import React, { useContext } from 'react';
import { Context } from '../../../context';
import { NavLink } from 'react-router-dom';
import './header.scss';
import Hamburger from './Hamburger';
import QuickLinks from '../../../assets/quicklinks/quickLinks.json';
import { ReactComponent as ProfileImage } from '../../../assets/images/header-icons/profile.svg';
import { ReactComponent as LightBulb } from '../../../assets/images/header-icons/lightbulb.svg';
function Header(props) {
  return (
    <>
      <div className='header-wrap'>
        <a href='/course/overview'>
          <div className='item-title'></div>
        </a>
        <div className='icons-box'>
          {/* <div className="icon profile">
            <LightBulb />
          </div> */}
          <NavLink to={'/profile'} activeClassName='active'>
            <div className='icon profile'>
              <ProfileImage />
            </div>
          </NavLink>
        </div>
        <Hamburger />
      </div>
    </>
  );
}

export default Header;
