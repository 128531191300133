import React, { useState, Fragment } from 'react';
import Modal from '../Modal/Modal';
import './reflection-questions.scss';

function Dynamic(props) {
  const [activeResponses, setActiveResponses] = useState([]);
  const [answerObject, changeAnswerObject] = useState(null);
  const [show, showModal] = useState(false);
  const onChangeValue = (answer, index) => {};
  return (
    <>
      <button className='button1' onClick={() => showModal(true)}>
        {`Question ${props.questionNumber}`}
      </button>
      <Modal show={show} showModal={showModal}>
        <div>
          <div className='item-wrap'>
            <h2 className='question'>{props.data.question}</h2>

            {props.data.answers.map((item, i) => (
              <Fragment key={i}>
                <div className='answers-reflect-wrap'>
                  {' '}
                  <div htmlFor=''>
                    {i + 1}. {item.answer}
                  </div>
                  <div className='answers'>
                    {item.options ? (
                      item.options.map((option, index) => (
                        <div className='radio-options' key={index}>
                          <label htmlFor={option}>{option}</label>
                          <input
                            className='radio'
                            type='radio'
                            name={`group${i}`}
                            onChange={() => onChangeValue(item, i)}
                          />
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                    {item.typeIn ? (
                      <textarea className='reflect-response'></textarea>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Fragment>
            ))}

            <button onClick={() => showModal(false)} className='button1'>
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Dynamic;
