import React, { Fragment, useContext, useState, useEffect } from 'react';
import './overview.scss';
import allBadges from '../../shared/badges/allBadges';
import badge1 from '../../../assets/badges/genericBadge.png';
import { Context } from '../../../context';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import { ReactComponent as SupportIcon } from '../../../assets/images/support.svg';
import VideoPlayer from '../../shared/video-player/video-player';
import { Redirect } from 'react-router-dom';
import flow from '../../../assets/flow.json';
import RightArrow from './RightArrow';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import VimeoPlayer from '../../shared/video-player/vimeo-player.js';
import setFirstVideo from '../../../assets/helpers.js/setFirstVideo';
const BadgeDisplay = ({ badge, complete }) => {
  return (
    // <a href={badge.urlUsed}>
    <img
      className={complete ? '' : 'greyed-out'}
      src={require(`../../../assets/badges/${badge.badgeName}.png`).default}
      alt='need to add alt'
    />
    // </a>
  );
};

function Overview(props) {
  const context = useContext(Context);
  const [firstVisit, setFirstVisit] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [completedBadges, setCompletedBadges] = useState(false);
  const [progress, setProgress] = useState(false);
  const handleBadgeGet = async () => {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + `/api/badge`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (data.ok) {
        let response = await data.json();

        setCompletedBadges(response);
      } else {
        const errorMessage = await data.text();
        throw new Error(errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let checkFirstVisit = localStorage.getItem('initialVideo') !== 'true';
    setFirstVisit(checkFirstVisit);
    handleBadgeGet();
    context.getProgressData(false).then((res) => {
      if (res) {
        setProgress(res);
      }
    });
    context.setSidebarModuleOpen(0);
  }, []);

  const sortBadges = (type) => {
    const completedCheck = (item) => {
      if (completedBadges) {
        return completedBadges.includes(item.badgeName);
      } else {
        return false;
      }
    };
    return allBadges
      .filter((item) => item.type === type)
      .map((item) => (
        <BadgeDisplay badge={item} complete={completedCheck(item)} />
      ));
  };

  const badgeTypes = [
    { header: 'Module', items: sortBadges('module') },
    { header: 'Activity', items: sortBadges('activity') },
  ];

  let modindex, linkindex, curLink, url;

  if (progress && localStorage.getItem('initialVideo') !== 'false') {
    modindex = progress.modindex;
    linkindex = Number(progress.linkindex);
    url = flow[modindex].links[linkindex].url;
  }

  return redirect ? (
    <Redirect to={'/course/module1/timeline/two-truths1/intro'} />
  ) : (
    <section className='welcome' id={'fade'}>
      <div>
        <h1>
          Welcome {!firstVisit && 'Back,'} {localStorage.getItem('name')}!
        </h1>
        {!firstVisit ? (
          <>
            <div className='flex-center'>
              <Link
                className={'takeMeWhere'}
                to={url}
                onClick={() => {
                  context.setCurModIndex(modindex);
                  context.setCurSectionIndex(linkindex);
                  context.setSidebarModuleOpen(modindex, modindex - 1);
                  context.setCurModLink(url);
                }}
              >
                <span>Take Me to Where I Left Off</span>
                <RightArrow />
              </Link>
            </div>
            <>
              <div className='middle-wrapper'>
                <section>
                  <h2>Badges</h2>
                  <div className='legendWrap'>
                    <div className='legend'>
                      <img src={badge1} alt='legend'></img>
                      <span>Completed</span>
                    </div>
                    <div className='legend'>
                      <img
                        className='greyed-out'
                        src={badge1}
                        alt='legend'
                      ></img>
                      <span>Not Completed</span>
                    </div>
                  </div>
                  {badgeTypes.map((type) => {
                    return (
                      <div className='sub-mod'>
                        <h3>{type.header + ' Badges'}</h3>
                        <div className='badge-flex'>{type.items}</div>
                      </div>
                    );
                  })}
                </section>
              </div>
              <div className='center-me'>
                <a
                  href='https://osu.az1.qualtrics.com/jfe/form/SV_5pzFbOpQv88AGma'
                  target={'_blank'}
                  className='supportButton supportWrapper'
                  rel='noreferrer'
                >
                  <SupportIcon />
                  <p>Need Tech Support?</p>
                </a>
              </div>
            </>
          </>
        ) : (
          <>
            <div className='video-parent'>
              <div className='video-wrap'>
                <VimeoPlayer
                  source={749604251}
                  onStart={() => {
                    setTimeout(() => {
                      setEnableButton(true);
                    }, 3000);
                  }}
                />
              </div>
              <p style={{ fontSize: '1.5rem' }}>
                Watch this video to unlock the modules and get started!
              </p>
            </div>
            <div
              className={`front-button-wrap ${
                !enableButton ? 'disabled' : 'enabled'
              }`}
            >
              <button
                onClick={
                  enableButton
                    ? () => {
                        if (firstVisit) {
                          setFirstVideo();
                          localStorage.setItem('initialVideo', 'true');
                          setRedirect(true);
                          context.postProgressData(1, 0);
                        }
                      }
                    : null
                }
                className='continue-button'
              >
                Let's Get Started!
              </button>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Overview;
