import React from 'react';
import './nav-buttons.scss';

function NavButtons(props) {
  return (
    <>
      <div className='nav-button-wrap'>
        <div className='button-box'>
          <button
            onClick={() => (props.loading ? '' : props.handleProgress(false))}
          >
            Back
          </button>

          <button
            onClick={() => (props.loading ? '' : props.handleProgress(true))}
            {...(props.submitID && { type: 'submit', form: props.submitID })}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default NavButtons;
