import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import useForgotPassword from '../../../assets/helpers.js/forgotPassword';

const ForgotUsernameOrPassword = () => {
  const [form, setForm] = useState({});
  const [res, callAPI] = useForgotPassword(form.username);

  const handleChangeInput = (element) => {
    const { name, value } = element;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const getAttributes = (name, state, handleChange) => {
    return {
      name: name,
      value: state[name] || '',
      onChange: (e) => handleChange(e.target),
    };
  };

  return (
    <div className='auth-wrapper'>
      <>
        <div className='sign-in-b-wrapper'>
          <Link className='sign-up' to='/login'>
            Back to Sign In
          </Link>
        </div>
        <section>
          <div className='logo'>&nbsp;</div>
          <div className='login-wrap'>
            <div className='inner-wrap'>
              <h1>Forgot Username or Password</h1>
              {res.isLoading && <span>...loading</span>}
              {!res.isLoading && (
                <>
                  {res.data && !res.isLoading ? (
                    <div className='success-wrap'>
                      <h1>Success!</h1>
                      <p>
                        Your username and a new password are on the way! If you
                        want to change the password that we send to you then
                        follow these steps!
                      </p>
                      <ol>
                        <li>Log In with your username and new password</li>
                        <li>Go to profile page and select "Reset Password"</li>
                        <li>Reset Password</li>
                      </ol>
                    </div>
                  ) : (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        callAPI();
                      }}
                    >
                      <label>
                        <p>Username</p>
                        <input
                          required
                          type='text'
                          {...getAttributes(
                            'username',
                            form,
                            handleChangeInput
                          )}
                        />
                      </label>
                      <p
                        style={{
                          textAlign: 'start',
                          color: '#272e68',
                          fontWeight: 'bold',
                        }}
                      >
                        Please enter your username and you will be sent a new
                        password. After you sign in with your new password,
                        navigate to your profile page and click reset password
                        if you would like to change this password.{' '}
                      </p>
                      <p
                        style={{
                          textAlign: 'start',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        Check Your Junk Mail!{' '}
                      </p>
                      <div className='button-wrap'>
                        <button className='button' type='submit'>
                          Reset Password
                        </button>
                      </div>
                    </form>
                  )}
                  {res.error && <p className='error'>Invalid Username</p>}
                </>
              )}
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default ForgotUsernameOrPassword;
