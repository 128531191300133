import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import ProfilePage from '../../components/core/profile/ProfilePage.js';
import { Consumer, Context } from '../../context.js';
import SideBar from '../../components/core/sidebar/sidebar.jsx';
import Header from '../../components/core/header/header.jsx';
import Flow from '../../assets/flow.json';
import './page-content.scss';
import Module from '../../components/course/Module.jsx';
import Overview from '../../components/course/Overview/overview.jsx';
import { gsap } from 'gsap';
import RightProgress from '../../components/core/right-progress/RightProgress.jsx';
import Login from '../../components/core/login/login.jsx';
import SignUp from '../../components/core/login/SignUp.js';
import ResetPassword from '../../components/core/login/ResetPassword.jsx';
import { useHistory } from 'react-router-dom';
import ForgotUsernameOrPassword from '../../components/core/login/ForgotUsernameOrPassword.js';
const Routes = () => {
  const context = useContext(Context);

  const watchedInitialVideo = localStorage.initialVideo === 'true';

  useEffect(() => {
    context.getProgressData().then((result) => {
      if (result) {
        const token = localStorage.getItem('token');
        context.login(token);

        let { modindex, linkindex } = result;
        let linkindex1 = Number(linkindex);

        if (typeof JSON.parse(linkindex) === 'object') {
        } else {
          if (window.location.pathname !== '/course/overview') {
            context.setCurModLink(Flow[modindex].links[linkindex1].url);
            context.setCurModIndex(modindex);
            context.setCurSectionIndex(linkindex1);
            context.setSidebarModuleOpen(modindex, modindex - 1);
          }
        }
      }

      if (!result) {
        context.logout();
      }
    });
  }, []);

  if (context.state.isAuth.loading) {
    return <></>;
  }

  return (
    <>
      {context.state.isAuth.auth ? (
        <Router>
          <div>
            <Header />
            <SideBar />
            <div className='course-material'>
              {/* Module Content */}
              <Switch>
                <Route path={'/profile'}>
                  <ProfilePage />
                </Route>
                <Route path={'/course/overview'}>
                  <Overview />
                </Route>
                <Route path={'/reset-password'}>
                  <ResetPassword />
                </Route>
                {watchedInitialVideo &&
                  Flow.map((item, i) => {
                    return (
                      <Route path={item.url}>
                        {item.type === 'Module' ? (
                          <Module
                            lastData={Flow[i - 1]}
                            nextData={Flow[i + 1]}
                            parentRoute={item.url}
                            data={item}
                            modIndex={i - 1}
                          />
                        ) : (
                          <Overview />
                        )}
                      </Route>
                    );
                  })}
                <Redirect
                  from={'/'}
                  to={
                    watchedInitialVideo
                      ? context.state.curModLink
                      : '/course/overview'
                  }
                />
              </Switch>
            </div>
          </div>
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route path={'/sign-up'}>
              <SignUp />
            </Route>
            <Route path={'/login'}>
              <Login />
            </Route>
            <Route path={'/reset-password'}>
              <ForgotUsernameOrPassword />
            </Route>
            <Redirect from={'/'} to={'/login'} />
          </Switch>
        </Router>
      )}
    </>
  );
};

export default Routes;
