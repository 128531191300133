import PageContent from './navigation/page-content/page-content';
import { Provider } from './context';
import './App.scss';

function App() {
  return (
  <div id = "App">
  <Provider>
    <PageContent />
  </Provider>
  </div>
  );
}

export default App;
