import React, { useEffect, useState } from "react";

export default function Response(props) {
  return (
    <div>
      {props.answerObject ? (
        <>
          <div
            className={`${
              props.answerObject[props.index].correct
                ? "quiz-response-correct"
                : "quiz-response-incorrect"
            }`}
          >
            {props.answerObject[props.index].response}
          </div>
        </>
      ) : (
        <>no</>
      )}
    </div>
  );
}
