import React, { useState, useEffect, useContext } from 'react';
import './timeline.scss';
import AssetService from '../../../utils/asset-load';
import ProgressBar from '../progress-bar/progress-bar';
import {
  Route,
  Switch,
  useHistory,
  Redirect,
  useLocation,
} from 'react-router-dom';
import ReflectionQuestions from '../../shared/reflection-questions/reflection-questions';
import VideoPlayer from '../../shared/video-player/video-player';
import NavButtons from '../nav-buttons/nav-buttons';
import Static from '../../shared/static-content/static-content';
import Quiz from '../../shared/quiz/quiz';
import { gsap } from 'gsap';
import Flow from '../../../assets/flow.json';
import { Context } from '../../../context';
import DevelopmentTip from '../../shared/development-tip/DevelopmentTip';
import ClickReveal from '../../activities/ClickReveal/ClickReveal';
import FaceSurvey from '../../shared/face-survey/FaceSurvey';
import PlanIt from '../../shared/plan-it/PlanIt';
import VoiceOver from '../../shared/voice-over/VoiceOver';
import './page-order.scss';
import { DragonDropWrapper } from '../../shared/DragonDrop/DragonDrop';
import Badge from '../../shared/badges/badge';
import Handout from '../../shared/handouts/Handout';
import Image from '../../shared/contentImages/image';

function Timeline(props) {
  const context = useContext(Context);
  const [timelineData, setTlData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [links, setLinks] = useState(['/']);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [currentModule, setCurrentModule] = useState(null);
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
    if (props.path.url === window.location.pathname) {
      handleDataFetch();
    } else {
      history.push(props.path.url);
      handleDataFetch();
    }
  }, [props.path]);

  useEffect(() => {
    setProgress(links.indexOf(window.location.pathname));
  }, [links]);

  useEffect(() => {
    setProgress(links.indexOf(location.pathname));
    // context.unlockModule(location.pathname);
  }, [location]);

  useEffect(() => {
    context.setTimelineProgress(progress, location.pathname);
  }, [progress]);

  const handleDataFetch = () => {
    let urlArray = props.path.url.split('/');
    let dataIndex = urlArray.indexOf('timeline') + 1;
    let currentModule = urlArray[urlArray.indexOf('timeline') - 1];
    let data = AssetService.getTimeline(urlArray[dataIndex], currentModule);
    setTlData(data);
    let array = [];
    data.map((item) => {
      array.push(item.url);
    });
    setLoading(false);
    setLinks(array);
  };

  const handleProgressState = (increment) => {
    let array = [];
    props.allLinks.map((object) => {
      array.push(object.url);
    });
    if (increment) {
      // continue
      if (progress < links.length - 1) {
        // progress to the next timeline page
        fadeContent(links[progress + 1]);
        setProgress((prevProg) => prevProg + 1);
      } else {
        setLoading(true);
        if (props.allLinks.length - 1 === context.state.curSectionIndex) {
          // last section in module

          if (props.nextData !== undefined) {
            // progress to the next modul
            fadeContent(props.nextData.links[0].url);
            context.setCurModLink(props.nextData.links[0].url);
            context.postProgressData(context.state.curModIndex + 1, 0);
            if (
              context.state.curModIndex === context.state.lastVisitedModIndex
            ) {
              context.setLastVisitedModIndex(
                context.state.lastVisitedModIndex + 1
              );
            }
            // set the current section to 0 because it starts a new section in a new module
            context.setCurSectionIndex(0);
          } else {
            // go back to default page
            fadeContent('/course/overview');
          }
        } else {
          // progress to the next section
          fadeContent(array[context.state.curSectionIndex + 1]);

          context.postProgressData(
            context.state.curModIndex,
            context.state.curSectionIndex + 1
          );
          // update linkIndexes value only when condition met lastVisitedModIndex value
          // otherwise user can visit the pages they visited
          if (
            context.state.curSectionIndex ===
              context.state.linkIndexes[context.state.lastVisitedModIndex] &&
            context.state.curModIndex === context.state.lastVisitedModIndex
          ) {
            context.setUpdateLinkIndexes(context.state.curModIndex);
          }
        }
        setProgress((prevProg) => prevProg + 1);
      }
    } else {
      // back
      if (progress > 0) {
        // go back in the timeline
        fadeContent(links[progress - 1]);
        setProgress((prevProg) => prevProg - 1);
      } else if (progress === 0) {
        // go back a section
        setLoading(true);
        if (array[context.state.curSectionIndex - 1] !== undefined) {
          let urlArray = array[context.state.curSectionIndex - 1].split('/');
          let dataIndex = urlArray.indexOf('timeline') + 1;
          let currentModule = urlArray[urlArray.indexOf('timeline') - 1];
          let data = AssetService.getTimeline(
            urlArray[dataIndex],
            currentModule
          );
          setTlData(data);
          context.setCurSectionIndex(context.state.curSectionIndex - 1);
          fadeContent(data[data.length - 1].url);
        } else if (props.lastData !== undefined) {
          // go back a module
          if (props.lastData.links.length > 0) {
            let lastItemarray = [];
            props.lastData.links.map((object) => {
              lastItemarray.push(object.url);
            });
            let urlArray = lastItemarray[lastItemarray.length - 1].split('/');
            let dataIndex = urlArray.indexOf('timeline') + 1;
            let currentModule = urlArray[urlArray.indexOf('timeline') - 1];
            let data = AssetService.getTimeline(
              urlArray[dataIndex],
              currentModule
            );
            setTlData(data);
            context.setCurSectionIndex(lastItemarray.length - 1);
            context.setSidebarModuleOpen(
              context.state.curModIndex,
              context.state.curModIndex - 1
            );
            fadeContent(data[data.length - 1].url);
          }
        }
      }
    }
  };

  useEffect(() => {
    document.getElementsByClassName('timeline-wrap').scrollToTop = 0;
  }, []);

  const fadeContent = (link) => {
    document.getElementsByClassName('timeline-wrap').scrollToTop = 0;
    gsap
      .to('.fadeIt', { opacity: 0, duration: 0.3 })
      .then(() => {
        history.push(link);
        window.scrollTo(0, 0);
      })
      .then(() => {
        gsap.to('.fadeIt', { opacity: 1, duration: 0.3 });
      });
  };

  return (
    <>
      {
        <div className='timeline-wrap'>
          <div className='top-section'>
            <div className='sub-section-title'>
              <p>{props.path.title}</p>
            </div>
            {timelineData ? <ProgressBar links={timelineData} /> : <></>}
          </div>

          <div className='slide-wrap'>
            <div className='fade-content' id='fade'>
              <Switch>
                {timelineData ? (
                  timelineData.map((item, i) => {
                    return (
                      <Route path={item.url} key={item.url}>
                        <TimeLineBody item={item} />
                        {timelineData && (
                          <NavButtons
                            progress={progress}
                            handleProgress={(increment) =>
                              handleProgressState(increment)
                            }
                            links={links}
                            submitID={item.submitID}
                            loading={loading}
                          />
                        )}
                      </Route>
                    );
                  })
                ) : (
                  <> no data</>
                )}
                {timelineData ? (
                  <>
                    <Redirect
                      from={props.parentRoute}
                      to={timelineData[0].url}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Switch>
            </div>
          </div>
        </div>
      }
    </>
  );
}

const TimeLineBody = ({ item }) => {
  return (
    <div id='fadeIt' className='fadeIt'>
      <div className={`${item.identifier ? item.identifier : ''}`}>
        {item.title ? <h2 className='title'>{item.title}</h2> : <></>}
        {item.static ? (
          <div className='static-wrap'>
            <Static data={item.static} />
          </div>
        ) : (
          <></>
        )}
        {item.video ? (
          item.video.map((item, i) => (
            <div className='video-parent' key={i}>
              <div className='video-wrap'>
                <VideoPlayer source={item.source} />
              </div>
              {item.reflection ? (
                <div className='reflections-questions-wrap'>
                  <ReflectionQuestions
                    className=''
                    dynamic={item.dynamic ? item.dynamic : ''}
                    questions={item.reflection}
                    questionsIntro={
                      item.questionsIntro ? item.questionsIntro : ''
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              {item.voiceOver ? (
                <div className='voiceOver'>
                  <VoiceOver content={item.voiceOver} />
                </div>
              ) : (
                <></>
              )}
            </div>
          ))
        ) : (
          <></>
        )}

        {item.quiz ? (
          <div className='quiz-wrap'>
            <Quiz
              path={item.quiz}
              activityBadge={item.activityBadge}
              submitID={item.submitID}
            />
          </div>
        ) : (
          <></>
        )}
        {item.devTip ? (
          <div className='devTip-wrap'>
            <DevelopmentTip content={item.devTip} />
          </div>
        ) : (
          <></>
        )}
        {item.clickReveal ? (
          <div className='click-reveal-wrap'>
            <ClickReveal path={item.clickReveal} />
          </div>
        ) : (
          <></>
        )}
        {item.voiceOver ? <VoiceOver content={item.voiceOver} /> : <></>}
        {item.DragonDrop && (
          <DragonDropWrapper
            content={item.DragonDrop}
            badge={item.activityBadge}
          />
        )}
        {/* {item.DragonDrop && (
                            <DragonDrop content={item.DragonDrop} />
                          )} */}
        {item.image && (
          <div className='image-wrap'>
            {' '}
            <Image content={item.image} />
          </div>
        )}
        {item.handout && (
          <div className='handout-wrap'>
            {' '}
            <Handout content={item.handout} />
          </div>
        )}
        {item.planIt ? <PlanIt path={item.planIt} /> : <></>}
        {item.badge && <Badge content={item.badge} TEST />}

        {item.faceSurvey ? <FaceSurvey /> : <></>}
      </div>
    </div>
  );
};

export default Timeline;
