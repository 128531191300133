import React from "react";
import reactDom from "react-dom";
import "./Modal.scss";
import { ReactComponent as Close } from "../../../assets/images/close.svg";

function Modal(props) {
  return reactDom.createPortal(
    <>
      <div className={`modal-wrap ${props.show ? "show-me" : "hide-me"}`}>
        <div
          className="grey-cover"
          onClick={() => props.showModal(false)}
        ></div>
        <div className="content-wrap">
          <button className="close" onClick={() => props.showModal(false)}>
            <Close />
          </button>
          {props.children}
        </div>{" "}
      </div>
    </>,
    document.getElementById("fade")
  );
}

export default Modal;
