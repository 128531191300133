import React from 'react';
import './video-player.scss';
import Vimeo from '@u-wave/react-vimeo';
// import ReactPlayer from "react-player"

function VideoPlayer(props) {
  let vid = 'https://www.youtube.com/embed/';

  return (
    // <>
    //   <Vimeo
    //     video={props.source}
    //     controls={true}
    //     showTitle={true}
    //     showPortrait={false}
    //     // responsive={true}
    //   />
    // </>
    <iframe
      src={vid + props.source}
      frameBorder='0'
      allow='autoplay; encrypted-media'
      allowFullScreen
      title='video'
    />
  );
}

export default VideoPlayer;
