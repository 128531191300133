import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../context';
import './profile.scss';
import VideoPlayer from '../../shared/video-player/video-player';
import { ReactComponent as SupportIcon } from '../../../assets/images/support.svg';
import VimeoPlayer from '../../shared/video-player/vimeo-player';
import allBadges from '../../shared/badges/allBadges';
import PDF from '../../../assets/images/health-eCertificate2.pdf';
import AddHome from '../../../assets/handouts/addToHome_HeK.pdf';
import getPDF from '../../../assets/helpers.js/getPDF';
import { Link } from 'react-router-dom';
function ProfilePage() {
  const context = useContext(Context);
  const [completedBadges, setCompletedBadges] = useState([]);
  const [completedCert, setCompletedCert] = useState();
  const handleBadgeGet = async () => {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + `/api/badge`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (data.ok) {
        let response = await data.json();

        setCompletedBadges(response);
      } else {
        const errorMessage = await data.text();
        throw new Error(errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [pdf, setPdf] = useState(false);

  useEffect(() => {
    getPDF().then((resp) => setPdf(resp));
  }, []);

  useEffect(() => {
    handleBadgeGet();
  }, []);

  useEffect(() => {
    if (completedBadges) {
      setCompletedCert(
        allBadges
          .filter((item) => item.type === 'module')
          .every((item) => completedBadges.includes(item.badgeName))
      );
    }
  }, [completedBadges]);

  return (
    <section className='slide-wrap' id={'fade'}>
      <h1 className='title'>Profile</h1>
      <div className='video-parent'>
        <div className='video-parent'>
          <div className='video-wrap'>
            <VimeoPlayer source={749604251} onEnd={() => {}} />
          </div>
        </div>
      </div>
      <div className='buttonWrap'>
        <div className='supportWrapper'>
          <Link to={'/reset-password'} className='  supportButton'>
            <p>Reset Password</p>
          </Link>
        </div>
        <a
          href={AddHome}
          target={'_blank'}
          className={'btn-1 add-home'}
          rel='noreferrer'
        >
          Add to Homescreen
        </a>
        <button
          className='btn-1 log-out'
          onClick={() => {
            context.logout();
          }}
        >
          Log Out
        </button>
        <button
          onClick={() => (completedCert && pdf ? window.open(pdf) : '')}
          className={`btn-1 download-cert ${!completedCert && 'not-complete'}`}
          rel='noreferrer'
        >
          Download Certificate
        </button>
      </div>
    </section>
  );
}

export default ProfilePage;
