import React, { useState, useEffect, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './DragonDrop.scss';
import ActivityBadge from '../badges/ActivityBadge';
import { useBadge } from '../../../helpers/useBadge';

//Funcitonal wrapper around class-based, DragonDrop component so that the custom hook useBadge can be used.

const DragonDropWrapper = (props) => {
  const [{ showBadge }, dispatch] = useBadge(false);
  return (
    <DragonDrop
      content={props.content}
      badge={props.badge}
      dispatch={dispatch}
      showBadge={showBadge}
    />
  );
};

class DragonDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.content,
    };
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];

    // if the ptype are matched, do not allow user to drag the option to wrong area.
    if (result.draggableId && finish) {
      const selectedItemType = this.state.options[result.draggableId].ptype;
      const droppedAreaType = finish.title;
      if (
        selectedItemType.toLowerCase() !== droppedAreaType.toLowerCase() &&
        droppedAreaType.toLowerCase() !== 'start'
      ) {
        setTimeout(() => {
          this.setState({
            showErrorMessage: true,
          });
        }, 0);

        setTimeout(() => {
          this.setState({
            showErrorMessage: false,
          });
        }, 2000);

        return;
      }
      if (
        selectedItemType.toLowerCase() !== droppedAreaType.toLowerCase() &&
        droppedAreaType.toLowerCase() !== 'start'
      ) {
        setTimeout(() => {
          this.setState({
            showErrorMessage: true,
          });
        }, 0);

        setTimeout(() => {
          this.setState({
            showErrorMessage: false,
          });
        }, 1000);

        return;
      } else if (droppedAreaType.toLowerCase() !== 'start') {
        setTimeout(() => {
          const id = result.draggableId;
          this.props.dispatch({ type: 'show', payload: this.props.badge });
          this.setState((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              [id]: {
                ...prevState.options[id],
                showCorrectResponse: true,
              },
            },
          }));
        }, 0);

        setTimeout(() => {
          const id = result.draggableId;
          this.setState((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              [id]: {
                ...prevState.options[id],
                showCorrectResponse: false,
              },
            },
          }));
        }, 5000);
      }
    }

    if (start === finish) {
      const newTaskIds = Array.from(start.optionIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        optionIds: newTaskIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn,
        },
      };

      this.setState(newState);
      return;
    }

    // Moving from one list to another
    // this.props.dispatch({ type: "show" });
    const startTaskIds = Array.from(start.optionIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      optionIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.optionIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      optionIds: finishTaskIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    this.setState(newState);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className='dragon-act-wrap'>
          <div
            className={`error-pop ${
              this.state.showErrorMessage ? 'open' : 'closed'
            }`}
          >
            <p>Try Again!</p>
          </div>
          {this.state.columnOrder.map((columnId) => {
            const column = this.state.columns[columnId];
            const options = column.optionIds.map(
              (optionId) => this.state.options[optionId]
            );
            return (
              <div className='column'>
                <h1>{column.title}</h1>
                <Droppable droppableId={column.id} type='TASK'>
                  {(provided, snapshot) => (
                    <div
                      className='draggable-area'
                      ref={provided.innerRef}
                      style={{
                        backgroundColor: snapshot.isDraggingOver
                          ? 'lightgrey'
                          : 'white',
                      }}
                      {...provided.droppableProps}
                    >
                      {options.map((option, index) => (
                        <Draggable
                          draggableId={option.id}
                          index={index}
                          key={option.id}
                        >
                          {(provided, snapshot) => (
                            <div>
                              <div
                                className='dragon-button'
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                {option.content}
                              </div>
                              <div
                                className='correct-response'
                                style={{
                                  opacity: option.showCorrectResponse ? 1 : 0,
                                  display: option.showCorrectResponse
                                    ? 'block'
                                    : 'none',
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: option.response,
                                }}
                              ></div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            );
          })}
        </div>
        {this.props.badge && (
          <ActivityBadge
            showBadge={this.props.showBadge}
            content={this.props.badge}
            dispatch={this.props.dispatch}
          />
        )}
      </DragDropContext>
    );
  }
}

export { DragonDropWrapper };
